import React, { memo, useState } from 'react';
import BaseModal from './BaseModal';
import Button from '../components/shared/Button';

const PopConfirmModal = ({
  title = '',
  description = '',
  onConfirm,
  okText = 'OK',
  children,
}) => {
  const [open, setOpen] = useState(false);

  const handleConfirm = () => {
    onConfirm();
    setOpen(false);
  };

  const action = (
    <>
      <Button title="" onClick={handleConfirm}>
        {okText}
      </Button>
    </>
  );

  return (
    <>
      <div
        onClick={() => setOpen(true)}
        onKeyDown={() => setOpen(true)}
        role="button"
        tabIndex="0"
      >
        {children}
      </div>
      {open && (
        <BaseModal title={title} state={[open, setOpen]} action={action}>
          <p className="leading-loose">{description}</p>
        </BaseModal>
      )}
    </>
  );
};

export default memo(PopConfirmModal);
